
// let apiURL = "https://processportalapi-dev.azurewebsites.net/api"; //development
// let alignAPIURL = "https://dalignapi-test.azurewebsites.net/api"; //development

// // let apiURL = "https://processportalapi.azurewebsites.net/api"; //production
// // let alignAPIURL = "https://alignapi-prod.azurewebsites.net/api"  //Production
// let apiURL = process.env.VUE_APP_PORTAL_URL; //Process Portal
// let alignAPIURL = process.env.VUE_APP_ALIGNAPI_URL; // Align API

// let apiURL = "https://apidev-marketplace.alignxcel.com/api"; //development
// let alignAPIURL = "https://apidev.alignxcel.com/api"; //development

let apiURL = "https://api-marketplace.alignxcel.com/api"; //production
let alignAPIURL = "https://api.alignxcel.com/api"; //production


// let apiURL = "https://processportalapi-staging.azurewebsites.net/api"; //Staging
// let alignAPIURL = "https://alignapi-prod-staging.azurewebsites.net/api"; //Staging

let fileLimit = 5242880;
let limitText = "5MB"
let releaseFileLimit = 2097152;
let releaseLimitText = "2MB";

export default {
  apiURL: apiURL,
  alignAPIURL: alignAPIURL,
  fileLimit: fileLimit,
  limitText: limitText,
  releaseFileLimit: releaseFileLimit,
  releaseLimitText: releaseLimitText
}
